// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: $badge-font-size;
  font-weight: $badge-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border: 0 solid transparent;
  @include border-radius($badge-border-radius);

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}

// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.

.badge-pill {
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }

  .btn .badge-#{$color} {
    @include badge-btn-variant($value);
  }

  .btn:hover .badge-#{$color} {
    @include badge-btn-hover-variant($value);
  }

  .btn:active .badge-#{$color} {
    @include badge-btn-variant($value);
  }
}

@each $color, $value in $colors {
  .badge-color-#{$color} {
    @include badge-variant($value);
  }

  .btn .badge-color-#{$color} {
    @include badge-btn-variant($value);
  }

  .btn:hover .badge-color-#{$color} {
    @include badge-btn-hover-variant($value);
  }

  .btn:active .badge-color-#{$color} {
    @include badge-btn-variant($value);
  }
}

@mixin badge-variant($bg) {
  //color: color-yiq($bg);
  //background-color: $bg;
  color: $bg;
  background-color: rgba($bg, .1);
  border-color: $bg;

  &[href] {
    @include hover-focus {
      color: color-yiq($bg);
      text-decoration: none;
      background-color: $bg;
    }
  }
}

@mixin badge-btn-variant($bg) {
  //color: color-yiq($bg);
  //background-color: $bg;
  color: color-yiq($bg);
  background-color: $bg;
  border-color: $bg;
}

@mixin badge-btn-hover-variant($bg) {
  //color: color-yiq($bg);
  //background-color: $bg;
  color: $bg;
  background-color: lighten($bg, 35%);
}

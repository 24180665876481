@mixin alert-variant($background, $border, $color) {
  color: $color;
  @include gradient-bg($background);
  border-color: transparent;
  //border-color: $border;

  hr {
    //border-top-color: darken($border, 5%);
    border-top-color: $border;
  }

  .alert-link {
    //color: darken($color, 10%);
    color: $color;
  }
}
